$black: rgb(35, 34, 34);
$black10: rgba(35, 34, 34, 0.1);
$white: #FFFFFF;
$white20: rgba(255, 255, 255, 0.2);
$gray: #ABB1C4;
$light-gray: #E5E7EA;
$lighter-gray: #F7F8FB;
$dark-gray: #6F6F71;
$blue: #3C88F7;
$light-blue: #E9F2FF;
$very-light-blue: rgba(233, 242, 255, 0.5);
$green: #155724;
$light-green: #D4EDDA;
$orange: #856404;
$light-orange: #FFE8CC;
$red: #DB222A;
$light-red: #F8D7DA;
$yellow: #856404;
$light-yellow: #FFF3CD;
$bright-red: red;
$green_blue: #086375;
$green_blue_light: #D1E3F2;
