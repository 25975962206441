.select {
  &.disabled {
    .select__button{
      background-color: $light-gray;
      pointer-events: none;
    }
    .select__value {
      background-color: $light-gray;
      pointer-events: none;
    }
  }

  position: relative;
  min-width: 120px;
  cursor: pointer;
  .select__button {
    padding: 8px 12px;
    background: white;
    color: #717170;
    border-radius: 6px;
    border: 1px solid $light-gray;
    width: 140px;
    display: flex;
    justify-content: space-between;
    outline: none;
  }

  .select__button__placeholder {
    color: $gray;
  }

  .select__list {
    position: absolute;
    top: 20px;
    left: 0;
    padding: 0;
    list-style-type: none;
    background: $white;
    border: 1px solid $light-gray;
    border-radius: 6px;
    width: 164px;
    min-width: 164px;
    color: $dark-gray;
    z-index: 3;
    max-height: 200px;
    overflow-y: auto;

    li {
      padding: 12px;
      cursor: pointer;
      &:hover {
        background-color: $light-gray;
        color: $black;
      }
    }
  }

  .select__value {
    padding: 8px 12px;
    background: $light-blue;
    color: $black;
    border-radius: 6px;
    border: 1px solid $light-blue;
    width: 140px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .select__value__trash {
    transition: 0.3s ease;
    cursor: pointer;
  }

  .select__value__trash:hover {
    color: $bright-red;
  }
}
