.sidebar {
  background: $white;
  width: 300px;
  height: calc(100vh - 20px);
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s ease;
  border-right: 1px solid $light-gray;
  overflow-y: auto;

  &.closed {
    width: 50px;
    padding: 10px 4px;
    .sidebar__resize-button {
      transform: rotate(0deg);
    }
    .sidebar__menu__item {
      text-align: center;
    }
    .sidebar__menu__profile {
      justify-content: center;
    }
    .sidebar__top {
      justify-content: center;
    }
  }

  .sidebar__resize-button {
    transform: rotate(180deg);
    cursor: pointer;
    transition: width 0.3s ease;
    padding: 10px;
    border-radius: 6px;
    &:hover {
      background: $light-gray;
    }
  }

  .sidebar__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
  }

  .sidebar__logo {
    width: 120px;
  }

  .sidebar__square-logo {
    width: 40px;
  }

  .sidebar__menu {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }

  .sidebar__menu__title {
    color: $dark-gray;
    margin: 16px 0 8px 4px;
  }

  .sidebar__menu__item {
    padding: 8px;
    color: $black;
    border-radius: 6px;
    text-decoration: none;
    transition: 0.2s ease;
    
    &.active, &.active:hover {
      background: $light-blue;
    }

    &:hover {
      background: $light-gray;
    }

    span {
      margin-left: 6px;
    }
  }

  .sidebar__last-block {
    border-top: 1px solid $light-gray;
  }

  .sidebar__menu__profile {
    display: flex;
    align-items: center;
  }

  .sidebar__menu__profile-picture {
    border-radius: 100%;
    width: 20px;
  }

  .sidebar__divider {
    margin: 20px 0;
    min-width: 100%;
    min-height: 1px;
    border-top: 1px solid $light-gray;
  }
}
