.text-area {

  .text-area__input {
    padding: 8px 12px;
    background: white;
    color: $dark-gray;
    border-radius: 6px;
    border: 1px solid $light-gray;
    display: flex;
    justify-content: space-between;
    font-size: 13px;
    font-family: 'Inter', sans-serif;
    width: calc(100% - 24px);
    min-height: 100px;
    resize: vertical;

    &.disabled {
      pointer-events: none;
    }

    &:focus {
      outline: none;
      border: 1px solid $light-gray;
    }
  }
}
