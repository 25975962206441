.tabs {
  padding: 0 20px;
  display: flex;
  background: $white;
  box-shadow: 0 4px 6px $black10;

  .tabs__item {
    padding: 0 8px 10px 8px;
    cursor: pointer;
    margin: 0 4px;

    &.active {
      border-bottom: 2px solid $blue;
    }
  }
  .tabs__item__icon {
    margin-right: 6px;
  }
}
