.people {
  padding: 20px;
  display: grid;
  grid-template-columns: 1.3fr 3fr;
  gap: 24px;
}

@media (max-width: 1600px) {
  .people {
    grid-template-columns: 2fr 3fr;
  }
}
