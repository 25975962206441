.global-search {
  margin-top: 10px;
  padding: 8px 12px;
  background: white;
  color: $dark-gray;
  border-radius: 6px;
  border: 1px solid $light-gray;
  cursor: pointer;

  &.closed {
    text-align: center;
    padding-left: 10px;
  }

  .global-search__text {
    margin-left: 6px;
  }
}
