.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $dark-gray;
  margin: 12px 0;

  .pagination__page-number {
    margin: 0 10px;
    color: $blue;
    font-weight: 600;
  }

  .pagination__button {
    cursor: pointer;
    color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    width: 26px;
    border-radius: 8px;
    border: 1px solid $blue;
    transition: 0.3s ease;

    &:hover {
      color: $white;
      background-color: $blue;
    }
  }
}
