.actions_creator {

  .actions_creator__list {
    display: grid;
    grid-template-columns: 14fr 1fr;
    

    .select, .select__button, .select__value {
      width: calc(100% - 16px);
    }
    .select__list {
      width: 100%;
    }

    &.already-added {
      margin-bottom: 20px;
    }
  }

  .actions_creator__contacts {
    margin-top: 20px;
  }
  .actions_creator__contacts__header {
    display: grid;
    align-items: center;
    grid-template-columns: 3fr 1fr 3fr 1fr 1fr 1fr 2fr;
    color: $black;
    font-weight: 600;
    padding: 12px 4px;
    border-bottom: 1px solid $light-gray;
  }
  .actions_creator__contact {
    display: grid;
    align-items: center;
    grid-template-columns: 1fr 3fr 3fr 1fr 1fr 1fr 2fr;
    color: $dark-gray;
    padding: 12px 4px;
    border-bottom: 1px solid $light-gray;

    &.active {
      background-color: $light-blue;
    }
  }

  .actions_creator__contact__img {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }

  .actions_creator__contact__icon__red {
    color: $bright-red;
  }

  .actions_creator__contact__icon__green {
    color: $green;
  }

  .actions_creator__contact__ctas {
    display: flex;
    justify-content: flex-start;
    grid-gap: 6px;
  }

  .actions_creator__list__select {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $blue;
    font-size: 20px;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  .actions_creator__list__remove {
    display: flex;
    align-items: center;
    justify-content: center;
    color: $red;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }
}
