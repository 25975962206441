.results {
  position: relative;
  width: calc(100% - 10px);
  background: $white;
  border-radius: 12px;
  border: 1px solid $light-gray;
  padding: 5px;
  max-height: calc(100vh - 190px);
  min-height: calc(100vh - 190px);
  overflow-x: auto;

  &.blurred {
    overflow: hidden;
  }

  .results__filters-layer {
    width: 100%;
    height: 100%;
    position: absolute;
    background: $white20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }

  .results__filters-layer__text {
    color: $dark-gray;
  }
  
  .results__filters-layer__ctas {
    display: flex;
    align-items: center;
  }

  .results__filters-layer__ctas__cancel {
    margin-right: 16px;
    color: $dark-gray;
    cursor: pointer;
  }

  .results__table {
    border-collapse: collapse;
    border-radius: 4px;

    &.results__table__search__companies {
      td, th {
        max-width: 400px;
      }
    }

    td, th {
      padding: 10px 18px;
      text-align: left;
      min-width: 10px;
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    tr, th {
      border-bottom: 1px solid $light-gray;
      height: 100%;
    }
    tr:last-child {
      border-bottom: none;
    }
    tbody tr {
      color: $dark-gray;
    }
    tbody tr:hover {
      background-color: $light-blue;
    }
    
    .results__table__with-picture {
      display: flex;
      align-items: center;
    }

    .results__table__link {
      text-decoration: none;
      color: inherit;
      display: flex;
      align-items: center;
    }

    .results__table__link__icon {
      margin-left: 8px;
      font-size: 10px;
    }

    .results__table__linkedin__icon {
      font-size: 18px;
      color: $blue;
    }
  }

  .results__table__with-picture__placeholder {
    border-radius: 100%;
    padding: 6px;
    width: 13px;
    height: 13px;
    margin-right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $light-blue;
    color: $blue;
    font-weight: 600;
    border: 1px solid $blue;
  }

  .results__table__picture {
    border-radius: 100%;
    margin-right: 6px;
    width: 24px;
  }

  .results__actions {
    display: flex;

    .results__actions__item {
      font-size: 12px;
      margin-right: 8px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 28px;
      height: 28px;
      background-color: $blue;
      color: $white;
      transition: 0.3s ease;
      cursor: pointer;

      &.ai {
        color: $white;
        background: linear-gradient(135deg, #B353FF 30%, $blue 100%);
        border: none;
      }

      &:hover {
        opacity: 0.7;
      }
    }
  }

  .results__table__status {
    padding: 4px 8px;
    border-radius: 20px;
    width: 80px;
    text-align: center;
    font-size: 12px;
    &.enriching {
      color: $dark-gray;
      background-color: $light-gray;
    }
    &.enriched {
      color: $green_blue;
      background-color: $green_blue_light;
    }
    &.connected {
      color: $blue;
      background-color: $light-blue;
    }
    &.meeting {
      color: $green;
    }
    &.opportunity {
      color: $green;
    }
    &.client {
      color: $green;
    }
  }

  .results__table__progress {
    position: relative;
    padding: 3px 6px;
    border-radius: 20px;
    width: 80px;
    text-align: center;
    font-size: 12px;
    overflow: hidden;
    background-color: $white;
    &.progress_weak {
      color: $red;
      border: 1px solid $red;
      .results__table__progress__bar {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $light-red;
        width: 10px;
        height: 24px;
      }
    }
    &.progress_medium {
      color: $green_blue;
    }
    &.progress_high {
      color: $blue;
    }
  }

  .results__table__not_found {
    span {
      margin-left: 6px;
    }
  }

  .results__table__progress__number {
    position: relative;
    z-index: 2;
  }

  .results__table__status__icon {
    margin-right: 6px;
  }

  .results__table__with-picture__checked {
    position: relative;
  }

  .results__table__with-picture__checked_icon {
    color: $blue;
    position: absolute;
    right: 0;
    top: -3px;
  }

  .results__table__enrich__cta {
    padding: 4px 8px;
    border-radius: 6px;
    width: 80px;
    text-align: center;
    font-size: 12px;
    background-color: $light-blue;
    color: $blue;
    cursor: pointer;
    transition: 0.3s ease;

    span {
      margin-left: 6px;
    }

    &:hover {
      background-color: $blue;
      color: $white;
    }
  }

  .results__multiple_actions {
    position: fixed;

    bottom: 20px;
    left: 64%;
    padding: 6px 10px;
    border: 1px solid $blue;
    background-color: $blue;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    color: $blue;

    .select__value {
      background-color: $white;
    }
  }

  .results__multiple_actions_item {
    display: flex;
    align-items: center;
    margin: 0 4px;
  }

  .results__multiple_actions_text {
    color: $white;
    margin: 0 12px 0 6px;
    font-size: 12px;
  }

  .results__multiple_actions__label {
    margin: 0 6px;
    font-weight: 600;
  }

  .results__multiple_actions__cta_text {
    margin-left: 6px;
    font-size: 12px;
  }
}
