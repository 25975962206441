.login {
  min-height: 100vh;
  background: linear-gradient($light-gray, $lighter-gray, $light-gray);
  display: flex;
  justify-content: center;
  align-items: center;

  .login__form__container {
    min-width: 400px;
    padding: 30px 60px 50px 60px;
    background: $white;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .login__logo {
    width: 200px;
  }

  .login__form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 16px;
    width: 100%;
  }

  .login__form__input {
    width: calc(100% - 24px) !important;
    padding: 8px 12px;
    margin: 8px 0;
    background: white;
    color: $dark-gray;
    border-radius: 6px;
    border: 1px solid $light-gray;
    width: 160px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;

    &:focus {
      outline: none;
      border: 1px solid $light-gray;
    }
  }

  .login__form__input__btn {
    border-radius: 6px;
    width: 100%;
    margin-top: 10px;
  }
}
