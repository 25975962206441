.text {

  .text__input {
    padding: 8px 12px;
    background: white;
    min-height: 20px;
    max-height: 20px;
    color: $dark-gray;
    border-radius: 6px;
    border: 1px solid $light-gray;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    width: calc(100% - 24px);

    &.disabled {
      pointer-events: none;
    }

    &:focus {
      outline: none;
      border: 1px solid $light-gray;
    }
  }
}
