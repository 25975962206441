.sequence-step-action {
  padding: 20px;
  background-color: $lighter-gray;
  border-radius: 12px;
  border: 1px solid $light-gray;
  margin: 30px;

  .sequence-step-action__title__container {
    display: flex;
    margin-bottom: 6px;
  }

  .sequence-step-action__title {
    font-weight: 600;
    margin: 0 0 0 6px;
  }

  .sequence-step-action__label {
    font-weight: 600;
    margin: 0 0 4px 0;
  }

  .sequence-step-action__desc {
    color: $dark-gray;
    font-style: italic;
    margin: 0 0 10px 0;
  }

  .sequence-step-action__text__container {
    margin: 20px 0;
  }

  .sequence-step-action__text__counter {
    text-align: right;
    color: $dark-gray;

    span {
      margin-left: 6px;
    }

    &.limit {
      color: $bright-red;
    }
  }
}
