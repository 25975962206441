.toggle {
  cursor: pointer;
  min-width: 44px;
  min-height: 24px;
  background: $light-gray;
  border-radius: 50px;
  display: flex;
  justify-content: flex-start;

  &.active {
    justify-content: flex-end;
    background: $light-blue;
    .toggle__button {
      background: $blue;
    }
  }

  .toggle__button {
    height: 22px;
    width: 22px;
    border-radius: 100%;
    background: $dark-gray;
    z-index: 2;
  }
}
