.topbar {
  background: $white;

  .topbar__common {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: calc(100% - 40px);
    height: 40px;
    padding: 10px 20px;
    border-bottom: 1px solid $light-gray;
  }

  .topbar__title {
    display: flex;
    justify-content: space-between;
    padding: 10px 20px 16px 20px;
  }

  .topbar__title__left {
    display: flex;
    align-items: center;

    h1 {
      margin: 0;
      font-size: 16px;
      margin: 0 6px;
      font-weight: 600;
    }
    svg {
      font-size: 16px;
      color: $gray;
    }
    p {
      margin: 0;
      font-size: 16px;
      color: $blue;
      font-weight: 600;
    }
  }

  .topbar__description__left {
    color: $dark-gray;
    margin: 6px 0 0 0;
    font-style: italic;
  }

  .topbar__common__ia {
    display: flex;
    align-items: center;
    color: $dark-gray;
    font-size: 14px;
    font-weight: 300;
    background: linear-gradient(270deg, #B353FF 0%, #FA636B 40%, $blue 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .topbar__common__ia__icon {
    margin-right: 6px;
    color: #7383D9;
  }

  .topbar__common__right {
    display: flex;
    align-items: center;
  }

  .topbar__common__notifications {
    position: relative;
    cursor: pointer;
  }

  .topbar__common__notifications__icon {
    color: $gray;
    margin-right: 30px;
    font-size: 18px;
  }

  .topbar__common__notifications__round {
    position: absolute;
    top: 0px;
    left: 10px;
    min-height: 5px;
    min-width: 5px;
    border-radius: 100%;
    z-index: 3;
    background: $bright-red;
  }

  .topbar__common__company {
    display: flex;
    align-items: center;
  }

  .topbar__common__company__plan {
    margin: 0;
    margin-left: 6px;
    color: $dark-gray;
  }

  .topbar__common__company__name {
    margin: 0;
    font-weight: 500;
    margin-left: 6px;
  }

  .topbar__common__company__logo {
    max-width: 30px;
    max-height: 30px;
  }
}
