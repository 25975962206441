.notification {
  position: fixed;
  bottom: 20px;
  right: 20px;
  min-width: 300px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;

  &.success {
    color: $green;
    background-color: $light-green;
  }

  &.error {
    color: $red;
    background-color: $light-red;
  }

  .notification__message {
    margin-left: 6px;
  }
}
