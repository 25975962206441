.sequence-show {
  position: relative;
  width: 100%;
  background: $white;
  border-radius: 12px;
  border: 1px solid $light-gray;
  padding: 0;
  max-height: calc(100vh - 190px);
  min-height: calc(100vh - 190px);
  overflow-x: auto;
}
