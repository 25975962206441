.main-btn {
  background: $blue;
  color: $white;
  border-radius: 8px;
  padding: 8px 12px;
  transition: 0.3s ease;
  font-weight: 500;
  cursor: pointer;
  border: none;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    background-color: $gray;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.small {
    font-size: 12px;
    padding: 4px 8px;
    width: 20px;
    max-height: 18px;
  }
}

.alt-btn {
  background: $white;
  color: $blue;
  border-radius: 8px;
  padding: 8px 12px;
  transition: 0.3s ease;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid $blue;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    border-color: $gray;
    border-color: $gray;
    color: $white;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.small {
    font-size: 12px;
    padding: 4px 8px;
    width: 20px;
    max-height: 18px;
  }
}

.remove-btn {
  background: $red;
  color: $white;
  border-radius: 8px;
  padding: 8px 12px;
  transition: 0.3s ease;
  font-weight: 500;
  cursor: pointer;
  border: 1px solid $red;
  text-align: center;

  &:hover {
    opacity: 0.8;
  }

  &.disabled {
    border-color: $gray;
    border-color: $gray;
    color: $white;
    cursor: not-allowed;
    pointer-events: none;
  }

  &.small {
    font-size: 12px;
    padding: 4px 8px;
    width: 20px;
    max-height: 18px;
  }
}