.list_selector {
  width: calc(100% - 40px);
  background: $white;
  border-radius: 12px;
  border: 1px solid $light-gray;
  padding: 20px;
  max-height: calc(100vh - 220px);
  min-height: calc(100vh - 220px);
  overflow-y: auto;


  .list_selector__header {
    display: grid;
    align-items: center;
    grid-template-columns: 4fr 1fr;
    color: $black;
    font-weight: 600;
    padding: 12px 8px;
    border-bottom: 1px solid $light-gray;
  }

  .list_selector__row {
    display: grid;
    align-items: center;
    grid-template-columns: 4fr 1fr;
    color: $dark-gray;
    padding: 12px 8px;
    border-bottom: 1px solid $light-gray;
  
    &.active {
      background-color: $light-blue;
    }
  }

  .list_selector__ctas {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
  }

  .list_selector__row__icon {
    margin-right: 6px;
  }
}
