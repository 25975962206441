.sequence-builder {
  width: calc(100% - 40px);
  background: $white;
  border-radius: 12px;
  border: 1px solid $light-gray;
  padding: 20px;
  max-height: calc(100vh - 230px);
  min-height: calc(100vh - 230px);
  overflow-y: auto;

  .sequence-builder__header {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .sequence-builder__header__item {
    cursor: pointer;
    color: $blue;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 24px;
    min-height: 24px;
    max-width: 24px;
    max-height: 24px;
    border-radius: 100%;
    border: 1px solid $blue;
    transition: 0.3s ease;
    margin: 6px 30px;

    &.complete {
      color: $white;
      background-color: $blue;
    }

    &.incomplete {
      color: $gray;
      border: 1px solid $gray;
    }

    &.active {
      box-shadow: 0px 0px 3px 3px $blue;
    }
  }

  .sequence-builder__header__separator {
    min-height: 1px;
    max-height: 1px;
    width: 100%;
    background-color: $light-gray;
  }

  .sequence-builder__step {
    margin-top: 10px;
  }

  .sequence-builder__step__intro {
    color: $dark-gray;
    margin-bottom: 30px;
    font-style: italic;
  }

  .sequence-builder__step__form-item {
    margin: 20px 0;
    width: 100%;
  }

  .sequence-builder__step__label {
    margin: 0 0 4px 0;
    color: $black;
    font-weight: 600;
  }

  .sequence-builder__step__description {
    margin: 0 0 10px 0;
    color: $dark-gray;
    font-style: italic;
  }

  .sequence-builder__step__steps {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sequence-builder__step__title {
    display: flex;
    align-items: center;
    grid-gap: 20px;

    .text {
      flex: 1;
    }
  }
}
