.sequence_step_choices {
  padding: 20px;

  .sequence_step_choices__title {
    color: $black;
    font-weight: 600;
    margin: 0 0 6px 0;
  }

  .sequence_step_choices__desc {
    color: $dark-gray;
    font-style: italic;
    margin: 0;
  }

  .sequence_step_choices__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    text-align: center;
    margin: 20px 0 40px 0;
  }
  .sequence_step_choice {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 240px;
    padding: 20px 0;
    border: 1px solid $dark-gray;
    border-radius: 12px;
    color: $dark-gray;
    background-color: $white;
    cursor: pointer;
    transition: 0.3s ease;

    &.active {
      box-shadow: 0px 0px 3px 3px $blue;
      background-color: $light-blue;
      color: $blue;
      border-color: $light-blue;

      &:hover { opacity: 1; }
    }

    &:hover {
      opacity: 0.7;
    }
  }

  .sequence_step_choice__text {
    margin: 10px 0 0 0;
    font-weight: 600;
  }

  .sequence_step_choice__action {
    display: flex;
  }

  .sequence_step_choice__action__text {
    margin: 0 0 0 6px;
  }
}
