.filters {
  width: calc(100% - 40px);
  background: $white;
  border-radius: 12px;
  border: 1px solid $light-gray;
  padding: 20px;
  max-height: calc(100vh - 220px);
  min-height: calc(100vh - 220px);
  overflow-y: auto;

  .filters__block {
    margin-bottom: 20px;
  }

  .filters__panel {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    font-weight: 500;

    span {
      margin-left: 6px;
    }
  }

  .filters__icon {
    transition: 0.3s ease;
    cursor: pointer;
    transform: rotate(180deg);

    &.closed {
      transform: rotate(0deg);
    }
  }

  .filters__list {
    margin: 12px 0 24px 0;
    transition: 0.3s ease;

    &.closed {
      display: none;
    }
  }

  .filters__list__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 16px 0;
  }

  .filters__list__item__with-two-selects {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 6px;
    .select {
      min-width: 30px;
      .select__button {
        width: 54px;
      }
      .select__list {
        width: 78px;
        min-width: 78px;
      }
      .select__value {
        width: 54px;
      }
    }
  }

  .filters__list__item__or {
    color: $dark-gray;
    margin: -10px 0;
  }
}
