.step-delay-choice {
  background-color: $lighter-gray;
  padding: 20px;
  box-shadow: 4px 0px 4px 1px $gray;

  .step-delay-choice__title {
    color: $black;
    font-weight: 600;
    margin: 0 0 6px 0;
  }

  .step-delay-choice__desc {
    color: $dark-gray;
    font-style: italic;
    margin: 0;
  }

  .step-delay-choice__list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-top: 20px;
  }
  .step-delay-choice__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 200px;
    padding: 20px;
    border: 1px solid $gray;
    border-radius: 12px;
    color: $dark-gray;
    background-color: $white;
    cursor: pointer;
    transition: 0.3s ease;
   
    &.days {
      cursor: auto;
    }

    &:hover {
      opacity: 0.7;
    }

    &.active {
      box-shadow: 0px 0px 3px 3px $blue;
      background-color: $light-blue;
      color: $blue;
    }
  }

  .step-delay-choice__item__delay {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .step-delay-choice__item__delay__text {
    margin: 0 0 0 6px;

    span {
      font-weight: 900;
    }
  }
  
  .step-delay-choice__days__selector {
    cursor: pointer;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 8px;
    border: 1px solid $gray;
  }
}