.form_select {
  position: relative;
  cursor: pointer;

  &.is_emoji {
    .select__button {
      max-width: 30px;
    }
  }

  .select__button {
    padding: 8px 12px;
    min-height: 20px;
    max-height: 20px;
    background: white;
    color: #717170;
    border-radius: 6px;
    border: 1px solid $light-gray;
    display: flex;
    justify-content: space-between;
    align-items: center;
    outline: none;
  }

  .select__button__icon {
    margin-left: 6px;
  }

  .select__list {
    position: absolute;
    top: 20px;
    left: 0;
    padding: 0;
    list-style-type: none;
    background: $white;
    border: 1px solid $light-gray;
    border-radius: 6px;
    color: $dark-gray;
    z-index: 3;
    max-height: 200px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;

    li {
      padding: 12px;
      border-radius: 8px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      &:hover {
        background-color: $light-gray;
        color: $black;
      }
    }
  }
}
