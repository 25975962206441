.search {
  &.disabled {
    .search__input{
      background-color: $light-gray;
      pointer-events: none;
    }
  }

  .search__input {
    padding: 8px 12px;
    background: white;
    color: $dark-gray;
    border-radius: 6px;
    border: 1px solid $light-gray;
    width: 140px;
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    &::placeholder {
      color: $gray;
    }

    &:focus {
      outline: none;
      border: 1px solid $light-gray;
    }
  }

  .search__value {
    padding: 8px 12px;
    background: $light-blue;
    color: $black;
    border-radius: 6px;
    border: 1px solid $light-blue;
    width: 140px;
    display: flex;
    justify-content: space-between;
  }

  .search__value__trash {
    cursor: pointer;

    &:hover {
      color: $bright-red;
    }
  }
}
