.sequence_step_creator {

  .sequence_step_creator__item {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    width: 300px;
    padding: 16px 0;
    border: 1px solid $blue;
    border-radius: 18px;
    color: $blue;
    background-color: $very-light-blue;
    cursor: pointer;

    &.active {
      box-shadow: 0px 0px 3px 3px $blue;
      border-color: $very-light-blue;
    }
  }

  .sequence_step_creator__item__text {
    margin: 10px 0 0 0;
    font-weight: 600;
  }

  .sequence_step_creator__item__delay {
    display: flex;
    margin-bottom: 10px;
  }

  .sequence_step_creator__item__delay__text {
    margin: 0 0 0 6px;
  }

  .sequence_step_creator__item__action {
    display: flex;
  }

  .sequence_step_creator__item__action__text {
    margin: 0 0 0 6px;
  }

  .sequence_step_creator__add {
    width: 30px;
    height: 30px;
    color: $blue;
    border: 1px solid $blue;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      background-color: $light-blue;
    }
  }

  .sequence_step_creator__separator {
    height: 24px;
    width: 1px;
    margin: 0 auto;
    background-color: $blue;
  }

  .sequence_step_creator__cta-container {
    margin-top: 30px;
  }

  .sequence_step_creator__number {
    position: absolute;
    left: -14px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $blue;
    border-radius: 100%;
    background-color: $light-blue;
  }
}