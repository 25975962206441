.actions_setup {
  padding: 30px 40px;

  .actions_setup__header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
  }

  .actions_setup__header__block {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
  }

  .actions_setup__header__from {
    padding: 10px 20px;
    background-color: $white;
    border-radius: 12px;
    border: 1px solid $light-gray;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 200px;
  }

  .actions_setup__header__from__infos {
    display: flex;
    align-items: center;
  }

  .actions_setup__header__event__icon {
    color: $gray;
    font-size: 18px;
    margin-right: 10px;
  }

  .actions_setup__header__from__name {
    color: $black;
    font-weight: 600;
    margin: 0 0 4px 0;
  }

  .actions_setup__header__from__email {
    color: $dark-gray;
    margin: 0;
  }

  .actions_setup__header__from__img {
    border-radius: 100%;
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }

  .actions_setup__item__infos {
    padding: 10px 20px;
    background-color: $white;
    border-radius: 12px;
    border: 1px solid $light-gray;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 200px;
  }
  
  .actions_setup__item__step {
    margin: 4px 20px;
    font-weight: 600;
    font-size: 14px;
  }

  .actions_setup__item {
    margin-bottom: 50px;
    padding: 20px;
    background-color: $lighter-gray;
    border-radius: 12px;
    border: 1px solid $light-gray;
  }

  .actions_setup__item__info  {
    display: flex;
    font-weight: 600;
  }

  .actions_setup__item__label {
    margin: 20px 0 6px 0;
    font-weight: 600;
  }

  .actions_setup__item__cta__container {
    margin: 16px auto 0 auto;
    max-width: 150px;
  }

  .actions_setup__item__cta__executed {
    text-align: center;
    color: $dark-gray;
  }

  .actions_setup__header__icon {
    color: $gray;
    display: flex;
    font-size: 18px;
    margin-right: 10px;
  }

  .actions_setup__header__chevron {
    color: $light-gray;
    display: flex;
    font-size: 18px;
    margin: 6px 0;
  }
}
