.list_creator {
  position: relative;
  max-width: 600px;
  margin: 20px auto;
  background: $white;
  border-radius: 12px;
  border: 1px solid $light-gray;
  padding: 20px;
  overflow-y: auto;
  max-height: calc(100vh - 230px);
  min-height: calc(100vh - 230px);
 
  .list_creator__item {
    margin: 0 0 30px 0;
  }

  .list_creator__label {
    color: $black;
    font-weight: 600;
    margin-bottom: 4px;
  }

  .list_creator__description {
    color: $dark-gray;
    font-style: italic;
    margin-bottom: 10px;
  }

  .list_creator__item__choices {
    display: flex;
  }

  .list_creator__item__choice {
    padding: 8px 16px;
    border: 1px solid $dark-gray;
    border-radius: 12px;
    color: $dark-gray;
    margin-right: 10px;
    cursor: pointer;

    &.active {
      border-color: $blue;
      box-shadow: 0px 0px 4px 1px $blue;
      color: $blue;
    }
  }

  .list_creator__item__choice__text {
    margin-left: 6px;
  }

  .list_creator__close_btn {
    position: absolute;
    top: 10px;
    right: 16px;
    color: $dark-gray;
    font-size: 18px;
    cursor: pointer;
    transition: 0.3s ease;

    &:hover {
      opacity: 0.7;
    }
  }

  .list_creator__item__title {
    display: flex;
    align-items: center;
    grid-gap: 20px;

    .text {
      flex: 1;
    }
  }
}
